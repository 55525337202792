<template>
  <el-dialog
    :title="type === 'add' ? '添加国家 / 地区' : '编辑国家 / 地区'"
    :visible.sync="addDialog"
    width="612px"
    class="dialog-warpper"
    :before-close="handleClose"
  >
    <el-form ref="form" :model="form" label-width="160px" :rules="rules" :validate-on-rule-change="false">
      <el-radio-group v-model="radio1" @change="onRadioChange">
        <el-form-item label="选择所属洲：" prop="continent">
          <el-radio :label="item.id" v-for="(item, index) in continentDictCode" :key="index">{{
            item.itemName
          }}</el-radio>
        </el-form-item>
      </el-radio-group>
      <el-form-item label="国家/地区中文名称：" prop="countrycn">
        <el-input v-model="form.countrycn" placeholder="输入国家/地区中文名称"></el-input>
      </el-form-item>
      <el-form-item label="国家/地区英文名称：" prop="countryen">
        <el-input v-model="form.countryen" placeholder="输入国家/地区英文名称"></el-input>
      </el-form-item>
      <el-form-item label="国家/地区两位代码：" prop="code">
        <el-input v-model="form.code" placeholder="输入国家/地区两位代码"></el-input>
      </el-form-item>
      <el-form-item label="是否需要填写税号：" prop="radio">
        <el-radio-group v-model="form.radio" @change="radioChange">
          <el-radio :label="item.id" v-for="(item, index) in taxList" :key="index">{{ item.taxType }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item label="报关金额币种：" prop="currentType"> -->
      <el-form-item label="报关金额币种：" prop="currentTax">
        <el-select
          v-if="currency.length"
          style="width: 246px"
          v-model="form.currentTax"
          placeholder="请选择报关金额币种"
          @change="handleChange"
        >
          <el-option v-for="item in currency || []" :key="item.id" :label="item.currency" :value="item.id"> </el-option>
        </el-select>
        <div v-else class="gears-wrapper">
          <router-link to="/fedex/currencyManage"> 前往设置币种 </router-link>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCanceled">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="handleConfirmed(type)">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addCounrty, updateCounrty } from '@/api/country'
import { mapGetters } from 'vuex'
export default {
  data() {
    var verifyCn = (rule, value, callback) => {
      if (/^[\u4e00-\u9fa5]{0,}$/.test(value) == false) {
        callback(new Error('请输入中文'))
      } else {
        callback()
      }
      callback()
    }
    var verifyEn = (rule, value, callback) => {
      if (/^[A-Za-z ]+$/.test(value) == false) {
        callback(new Error('请输入英文'))
      } else {
        callback()
      }
      callback()
    }
    var verifyCode = (rule, value, callback) => {
      if (/^[A-Za-z]{2}$/.test(value) == false) {
        callback(new Error('请输入两位英文'))
      } else {
        callback()
      }
      callback()
    }
    return {
      taxList: [
        { id: 0, taxType: 'IOOS税号' },
        { id: 1, taxType: '英国税号' },
        { id: 2, taxType: '否' }
      ],
      query: {},
      radio1: this.radio,
      // radio2: this.radio3,
      loading: false,
      // currentTax1: this.currentTax,
      rules: {
        continent: [{ required: true, message: '请选择所属洲', trigger: 'blur' }],
        countrycn: [
          { required: true, message: '国家/地区中文名称不能为空', trigger: 'blur' },
          { validator: verifyCn, trigger: 'blur' }
        ],
        countryen: [
          { required: true, message: '国家/地区英文名称不能为空', trigger: 'blur' },
          { validator: verifyEn, trigger: 'blur' }
        ],
        code: [
          { required: true, message: '国家/地区两位代码不能为空', trigger: 'blur' },
          { validator: verifyCode, trigger: 'blur' }
        ],
        radio: [{ required: true, message: '税号不能为空', trigger: 'blur' }],
        currentTax: [{ required: true, message: '报关金额币种不能为空', trigger: 'blur' }]
      }
    }
  },
  props: {
    form: {
      type: Object
    },
    id: {
      type: Number
    },
    addDialog: {
      type: Boolean,
      deafult: false
    },
    type: {
      type: String,
      default: 'add'
    },
    radio: {
      type: Number
    },
    sup_this: {
      type: Object,
      required: true
    },
    currency: {
      type: Array
    }
  },
  computed: {
    ...mapGetters(['continentDictCode'])
  },
  watch: {
    radio: {
      handler(val) {
        this.radio1 = val
      },
      immediate: true
    },
    radio1(val) {
      this.$emit('update:radio', val)
    }
  },
  methods: {
    handleChange() {
      this.$refs.form.clearValidate('currentTax')
    },
    radioChange() {},
    handleClose() {
      // this.$refs.form.resetFields();
      this.$refs.form.clearValidate()
      this.$emit('update:addDialog', false)
    },
    onRadioChange(item) {
      this.form.continent = item
    },
    handleCanceled() {
      this.$emit('update:addDialog', false)
      this.$refs.form.clearValidate()
    },
    handleConfirmed() {
      console.log('this.form', this.form)
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.loading = true
        this.form.code = this.form.code.toUpperCase()
        if (this.type === 'add') {
          const data = {
            continentDictCode: this.form.continent,
            countryCnName: this.form.countrycn,
            countryEnName: this.form.countryen,
            twoCharCode: this.form.code,
            prepaymentType: this.form.radio,
            currencyId: this.form.currentTax
          }
          addCounrty(data)
            .then(() => {
              this.loading = false
              this.$emit('update:addDialog', false)
              this.query = {}
              this.sup_this.refreshPage()
              this.$message.success('国家添加成功！')
              this.$refs.form.resetFields()
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          if (
            this.continent === this.form.continent &&
            this.countryen === this.form.countryen &&
            this.countrycn === this.form.countrycn &&
            this.code === this.form.code
          ) {
            this.$emit('update:addDialog', false)
            return
          }
          const list = {
            id: this.id,
            continentDictCode: this.form.continent,
            countryCnName: this.form.countrycn,
            countryEnName: this.form.countryen,
            twoCharCode: this.form.code,
            prepaymentType: this.form.radio,
            currencyId: this.form.currentTax
          }
          updateCounrty(list)
            .then(() => {
              this.loading = false
              this.$emit('update:addDialog', false)
              this.query = {}
              this.sup_this.refreshPage()
              this.$message.success('更新国家信息成功！')
              this.$refs.form.resetFields()
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  .gears-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3841db;
    width: 246px;
    min-height: 40px;
    border: 1px solid #ebeef5;
  }
  ::v-deep {
    .el-form-item__label {
      color: #595961;
      font-weight: normal;
    }
    .el-radio__label {
      font-weight: normal;
    }
    .el-radio {
      margin-right: 15px;
    }
    .el-dialog__title {
      font-size: 14px;
      color: #1a1a1a;
    }
    .el-dialog__header {
      height: 52px;
      line-height: 52px;
      padding: 0 0 0 24px;
      border: 1px solid #dcdee0;
    }
    .el-input__inner {
      width: 246px;
    }
  }
}
</style>
